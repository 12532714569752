import { useEffect } from 'react'

const RedirectToZelda = () => {
    useEffect(() => {
        // Accessing /workouts will cause a 301 redirect to Zelda (see: .infrastructure/web/infra.yaml)
        window.location.href = 'https://app.pvolve.com/workouts'
    }, [])

    return null
}

export default RedirectToZelda
